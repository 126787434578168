import { ReactNode } from "react";
import styles from "./ExternalLink.module.scss";
import { Col, Row } from "antd";
import { ExportOutlined } from "@ant-design/icons";
export const ExternalLink = (props: {
  href: string;
  hideIcon?: boolean;
  children: ReactNode;
}) => {
  return <a href={props.href} target="_blank" rel="noreferrer" className={styles.externalLink} data-sentry-component="ExternalLink" data-sentry-source-file="ExternalLink.tsx">
      <Row justify="space-between" data-sentry-element="Row" data-sentry-source-file="ExternalLink.tsx">
        <Col data-sentry-element="Col" data-sentry-source-file="ExternalLink.tsx">{props.children}</Col>
        {!props.hideIcon && <Col>
            <ExportOutlined />
          </Col>}
      </Row>
    </a>;
};