import { Table } from "antd";
import { ReactNode } from "react";
import { JobSchema } from "../types/JobSchema";
import { useTranslation } from "react-i18next";
export interface JobTableBodyProps<T extends JobSchema> {
  jobSchemas: Required<T>[];
  renderers: {
    jobInformation: (record: Required<T>) => ReactNode;
    status: (record: Required<T>) => ReactNode;
    actions: (record: Required<T>, props: any) => ReactNode;
  };
}
export const JobTableBody = <T extends JobSchema,>(props: JobTableBodyProps<T>) => {
  const {
    t
  } = useTranslation("tools", {
    keyPrefix: "aggregate.remoteJobs"
  });
  return <Table dataSource={props.jobSchemas} columns={[{
    title: t("job"),
    dataIndex: "name",
    key: "name",
    render: (_, record) => props.renderers.jobInformation(record)
  }, {
    title: "Status",
    dataIndex: "lastPollResult",
    key: "status",
    width: "150px",
    render: (_, record) => props.renderers.status(record)
  }, {
    title: "Actions",
    width: "96px",
    render: (_, record) => props.renderers.actions(record, props)
  }]} rowKey="id" pagination={false} data-sentry-element="Table" data-sentry-component="JobTableBody" data-sentry-source-file="JobTableBody.tsx" />;
};