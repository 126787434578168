import { anaHazardList } from "@/lib/crg_apis/constants/hazards";
import { HubFormItem } from "@/components/inputs/HubFormItem";
import { buildYearListFromString } from "@/utils/years";
import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { Settings, StepsSchema } from "../types/settings";
import { buildSettings } from "../utils/settings";
import { TemplateMode } from "../utils/templates";
import { HazardsSelector } from "./HazardsSelector";
import { TemplateSelector } from "./TemplateSelector";
import { YearsInput } from "./YearsInput";
export interface SettingsFormProps {
  setSettings: (settings?: Settings) => void;
}

/**
 * Form to select a template or custom settings for a HACS run.
 * @returns Component.
 */
export const SettingsForm = ({
  setSettings
}: SettingsFormProps) => {
  // The settings from the selector - if set to "Custom", this is undefined.
  const [templateSettings, setTemplateSettings] = useState<Settings | undefined>(undefined);
  const [mode, setMode] = useState<TemplateMode>("severities");
  const [hazards, setHazards] = useState([...anaHazardList]);
  const [years, setYears] = useState(buildYearListFromString("1990-2100"));
  const steps: StepsSchema = [{
    type: "full_chain"
  }, {
    type: "export_all_rcps"
  }];
  useEffect(() => {
    if (templateSettings) {
      setSettings(templateSettings);
      return;
    }

    // Build custom settings.
    setSettings(hazards.length && years.length ? buildSettings({
      mode,
      hazards,
      steps,
      years
    }) : undefined);
  }, [templateSettings, mode, hazards, years]);
  return <div className="text-left" data-sentry-component="SettingsForm" data-sentry-source-file="SettingsForm.tsx">
      <Form style={{
      borderBottom: "1px solid lightgrey",
      marginBottom: "1rem"
    }} layout="vertical" data-sentry-element="Form" data-sentry-source-file="SettingsForm.tsx">
        <HubFormItem label="Template" data-sentry-element="HubFormItem" data-sentry-source-file="SettingsForm.tsx">
          <TemplateSelector onChange={setTemplateSettings} data-sentry-element="TemplateSelector" data-sentry-source-file="SettingsForm.tsx" />
        </HubFormItem>
      </Form>
      {!templateSettings && <Form layout="vertical">
          <HubFormItem label="Process Mode">
            <Select onChange={setMode} value={mode}>
              <Select.Option key="severities">Severities</Select.Option>
              <Select.Option key="structural">Structural</Select.Option>
            </Select>
          </HubFormItem>

          <YearsInput setYears={setYears} years={years} />

          <HazardsSelector hazards={hazards} onChange={setHazards} />
          {/* <HazardsSetup
           hazards={hazards}
           /> */}
        </Form>}
    </div>;
};