import { RiskBand } from "@/features/risk_bands";
import { HubGeocoderResult } from "@/features/geocoders";
import { Table, TableProps } from "antd";
import styles from "./MultipleResultsTable.module.scss";
import { RiskBandsResponse, RiskBandsResponseSingleRcp } from "@/store/services/facades/types";
import { AbcMetadata } from "@/store/services/facades/types/abc";
import { HaloMetadata } from "@/store/services/facades/types/halo";
export interface GeocodeWithResult {
  geocode: HubGeocoderResult;
  result: RiskBandsResponse;
}
export interface GeocodeWithSingleRcp {
  geocode: HubGeocoderResult;
  result: RiskBandsResponseSingleRcp;
  metadata?: AbcMetadata | HaloMetadata;
}
interface AbcColumnProps {
  dataIndex: string;
  title: string;
}
interface MultipleResultsTableProps extends TableProps<any> {
  data: GeocodeWithSingleRcp[];
}
const buildAbcColumn = ({
  dataIndex,
  title
}: AbcColumnProps): any => {
  return {
    dataIndex,
    title,
    defaultSortOrder: "descend",
    sorter: {
      compare: (a: any, b: any) => a[dataIndex].rating > b[dataIndex].rating ? 1 : -1
    },
    sortDirections: ["ascend", "descend"],
    render: ({
      rating
    }: any) => <RiskBand rating={rating} size={24} />
  };
};
export const MultipleResultsTable = ({
  data,
  ...props
}: MultipleResultsTableProps) => {
  if (!data.length) {
    return <></>;
  }
  const columns = "ratings" in data[0].result ? data[0].result.ratings.map(({
    year
  }, index) => {
    return {
      title: String(year),
      sorter: {
        compare: (a: any, b: any) => a.ratings[index].rating > b.ratings[index].rating ? 1 : -1
      },
      sortDirections: ["descend", "ascend"],
      render: ({
        ratings
      }: any) => <RiskBand rating={ratings[index].rating} size={24} />
    };
  }) : [buildAbcColumn({
    dataIndex: "current_year",
    title: String(data[0].result.current_year.year)
  }), buildAbcColumn({
    dataIndex: "end_of_mortgage_term",
    title: String(data[0].result.end_of_mortgage_term.year)
  }), buildAbcColumn({
    dataIndex: "end_of_century",
    title: String(data[0].result.end_of_century.year)
  })];
  return <Table pagination={false} columns={[{
    dataIndex: "input",
    title: "Provided Address",
    width: "70%",
    render: name => <div style={{
      minWidth: "400px"
    }}>{name}</div>
  }, {
    dataIndex: "quality",
    title: "Geocode Quality",
    render: name => <div style={{
      minWidth: "140px"
    }}>{name}</div>
  }, ...columns]} dataSource={data.map(({
    geocode,
    result
  }, index) => ({
    input: geocode.input,
    quality: geocode.quality,
    key: index,
    ...result
  }))} className={styles.table} {...props} data-sentry-element="Table" data-sentry-component="MultipleResultsTable" data-sentry-source-file="MultipleResultsTable.tsx" />;
};