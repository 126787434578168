import { getConfig } from "@/features/branding";
import { encryptData } from "@/features/cryptography";
import { HacsOmUploadData } from "@/features/hacs";
import { RemoteNewRunModal, RemoteNewRunModalProps } from "@/features/remote_jobs";
import { StoreState } from "@/store";
import { useGetOrgQuery } from "@/store/services/supabase";
import { PortfolioExportIdFactory } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportIdFactory";
import { PortfolioExportErrorAlert } from "@/tools/aggregate/portfolio-export/components/PortfolioExportErrorAlert";
import { PortfolioExportUploadForm } from "@/tools/aggregate/portfolio-export/components/PortfolioExportUploadForm";
import { PortfolioExportUploadReview } from "@/tools/aggregate/portfolio-export/components/PortfolioExportUploadReview";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { streamToSupabase } from "@/tools/aggregate/portfolio-export/utils/supabase";
import { validateCsvFile } from "@/tools/aggregate/portfolio-export/utils/validation";
import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { removeFileExtension } from "@/utils/string";
import { Timeline } from "antd";
import { RcFile } from "antd/es/upload";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 * Provides an interface for a user to setup and  trigger a new Portfolio Export
 * run.
 */
export const PortfolioExportNewRunModal = (props: RemoteNewRunModalProps<PortfolioExportJobSchema>) => {
  const [data, setData] = useState<HacsOmUploadData | undefined>();
  const [status, setStatus] = useState<"INITIAL" | "IN_REVIEW" | "UPLOADING">("INITIAL");
  const [errors, setErrors] = useState<string[]>([]);
  const [key] = useKeyPair();
  const {
    data: org
  } = useGetOrgQuery();
  const validateFile = async (file: RcFile) => {
    setErrors([]);
    const {
      portfolioExport: config
    } = getConfig();
    const portfolioCount = {
      min: org?.is_large_bank ? config.largeBank.minAddresses : 0,
      max: config.maxAddresses
    };
    const {
      status,
      messages
    } = await validateCsvFile(file, portfolioCount);
    if (status === "error") {
      setErrors(messages);
      setData(undefined);
      setStatus("INITIAL");
      return;
    }
  };
  const uploadFile = async (file: RcFile) => {
    const idFactory = new PortfolioExportIdFactory();
    const [supabaseStream] = file.stream().tee();
    const portfolioDetails = {
      name: await encryptData(key.user!.keyPair, data?.name || removeFileExtension(file.name) || ""),
      startTime: Date.now()
    };
    if (!key.user) return;
    const supabaseResponse = await streamToSupabase(supabaseStream, idFactory, portfolioDetails, key.user.keyPair);
    if (supabaseResponse.type === "failure") {
      setErrors(supabaseResponse.errors);
      return;
    }

    // We just need to alert down the line that we should close the modal.
    if (props.onCancel) {
      props.onCancel({} as any);
    }
    setStatus("INITIAL");
  };
  const totalUploaded = useSelector(({
    portfolioExport
  }: StoreState) => portfolioExport.newRun.totalInSupabase);
  useEffect(() => {
    if (data) {
      switch (status) {
        case "IN_REVIEW":
          validateFile(data.file);
          return;
        case "UPLOADING":
          uploadFile(data.file);
          return;
      }
    }
  }, [data, status]);
  return <RemoteNewRunModal closable={status !== "UPLOADING"} maskClosable={status === "INITIAL"} width={560} {...props} data-sentry-element="RemoteNewRunModal" data-sentry-component="PortfolioExportNewRunModal" data-sentry-source-file="PortfolioExportNewRunModal.tsx">
      <div style={{
      paddingBlockStart: 8
    }}>
        {!!errors.length && <PortfolioExportErrorAlert errors={errors} onClose={() => setErrors([])} />}

        {!data && status === "INITIAL" && <PortfolioExportUploadForm data={data} setData={data => {
        setData(data);
        if (data) {
          setStatus("IN_REVIEW");
        }
      }} />}

        {data && status === "IN_REVIEW" && <PortfolioExportUploadReview data={data} setData={setData} onContinue={() => {
        setStatus("UPLOADING");
      }} onBack={() => {
        setData(undefined);
        setStatus("INITIAL");
      }} />}

        {data && status === "UPLOADING" && <div style={{
        padding: "48px 32px 20px 32px",
        borderRadius: 8,
        background: "rgba(0, 0, 0, 0.02)",
        border: "1px solid rgba(217, 217, 217, 0.4)"
      }}>
            <Timeline items={[{
          children: `${totalUploaded} encrypted assets uploaded.`
        }]} pending={<strong>Upload in progress. Do not close your browser.</strong>} />
          </div>}
      </div>
    </RemoteNewRunModal>;
};