import { getTranslatedText } from "@/features/internationalisation";
import { ensureGradedRiskValue } from "@/features/risk_bands";
import { anaHazards } from "@/lib/crg_apis/constants/hazards";
import { RiskBandsRiskValue } from "@/store/services/facades/types";
import { HaloBatchResultsResponseSuccess, HaloBatchResultsResponse } from "@/store/services/facades/types/halo";
import { SimpleStructuralBatchResultsResponse, SimpleStructuralBatchResultsResponseSuccess } from "@/store/services/facades/types/simple";
import { PortfolioExportResultError, PortfolioExportResultLine } from "@/tools/aggregate/portfolio-export/types";
type Response = HaloBatchResultsResponse | SimpleStructuralBatchResultsResponse;
type ResponseSuccess = HaloBatchResultsResponseSuccess | SimpleStructuralBatchResultsResponseSuccess;

/**
 * Transform the Portfolio Export results into useable objects.
 */
export class PortfolioExportResultsTransformStream extends TransformStream<BufferSource, PortfolioExportResultLine | PortfolioExportResultError> {
  constructor() {
    /** Helper to decode chunks. */
    const decoder = new TextDecoder();
    /**  */
    let lastTail = "";

    /**
     * Converts a line into the format we'd like to use for asset-level details.
     * @param line - Raw string of JSON.
     * @returns
     */
    const convertLine = (line: string): (PortfolioExportResultLine | PortfolioExportResultError)[] => {
      let result: Response;
      try {
        // Remove leading ASCII Record Separator if it exists on this JSONLine.
        result = line.startsWith("\x1E") ? JSON.parse(line.slice(1)) : JSON.parse(line);
      } catch (err) {
        console.error(err);
        return [];
      }
      if ("error" in result) {
        const {
          status,
          title,
          detail
        } = result.error;

        // It is noted that 500-class errors are Façades' way of telling us that
        // "this hasn't processed yet".
        if (status >= 500 && status < 600) {
          return [];
        }
        return [{
          status: "error",
          id: result.item_id,
          title,
          detail
        }];
      }
      const buildLine = (scenario: string, year: number, riskBand: RiskBandsRiskValue, hazards: string[]): PortfolioExportResultLine => {
        const {
          metadata
        } = (result as ResponseSuccess).result;
        return {
          status: "success",
          id: result.item_id,
          geocodeQuality: metadata.geocoding?.quality || "unknown",
          scenario,
          year,
          riskBand: ensureGradedRiskValue(riskBand),
          hazards: hazards.join(";"),
          location: metadata.location
        };
      };
      return Object.keys(result.result).reduce((lines, key) => {
        if (!key.startsWith("rcp")) {
          return lines;
        }
        const rcp = (result as ResponseSuccess).result[key as "rcp26"];
        const rcpName = getTranslatedText(`terms:scenarios.${key}.medium`);
        const hazards = rcp.top_significant_hazards.map(hazard => getTranslatedText(`terms:hazards.${anaHazards[hazard].newId}`));
        const ratings = rcp.ratings.map(rating => {
          return buildLine(rcpName, rating.year, rating.rating!, hazards);
        });
        return [...lines, ...ratings];
      }, [] as any[]);
    };
    super({
      transform: async (_chunk, controller) => {
        let chunk: BufferSource;
        try {
          chunk = await _chunk;
        } catch (err) {
          controller.terminate();
          return;
        }
        const lines = decoder.decode(chunk).split("\n");
        if (lines.length < 2) {
          // the chunk is not a complete line
          // accumulate & skip to the next chunk
          lastTail += lines[0];
          return;
        }
        lines.forEach((line, index) => {
          switch (index) {
            case 0:
              for (const row of convertLine(lastTail + line)) {
                controller.enqueue(row);
              }
              break;
            case lines.length - 1:
              lastTail = line;
              break;
            default:
              for (const row of convertLine(line)) {
                controller.enqueue(row);
              }
          }
        });
      },
      flush: controller => {
        // Send the very final line.
        if (lastTail) {
          for (const row of convertLine(lastTail)) {
            controller.enqueue(row);
          }
        }
        controller.terminate();
      }
    });
  }
}