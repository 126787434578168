import { latSchema, lngSchema } from "@/lib/crg_apis/types/latlng";
import { z } from "zod";

// silly me; can't seem to figure this out :'(
// import { getConfig } from "@/features/branding";
// const constraints = getConfig().inputConstraints;

const constraints = {
  // NB this is a duplicate of the theme base; see silly comment above
  addressLength: {
    min: 8,
    max: 200
  },
  buildYear: {
    min: 1600,
    max: 2100
  },
  floorHeight: {
    min: 0,
    max: 10000
  }
};

// leave the " (optional, blah blah blah)" off fields in the errors list
const trimAdornment = (f: string | number) => f.toString().replace(/ \(.+/, "");

/** Parses an enum ZodError and generates good error messages. */
const enumErrorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_enum_value:
      {
        const field = trimAdornment(error.path[0]);
        const opts = error.options.join("', '");
        return {
          message: `Incorrect value "${error.received}" given for "${field}" - correct values are: "${opts}."`
        };
      }
  }
  return {
    message: ctx.defaultError
  };
};
const archetypes = ["House", "Townhouse", "Apartment", "High-rise Residential Flat", "Low-rise Residential Flat", "Freestanding House", "Shops/Shopping Malls", "Commercial Skyscraper", "Commercial Building", "Simple House", "High Rise Flat", "Low Rise Flat", "Simple Office", "Low Rise Structure", "High Rise Structure", "Supermarket", "Control Room", "Substation", "Wind Turbine", "Park Complex", "Council Building", "Land", "Generic Industrial", "Track Main", "Gas Distribution", "Overhead Power Pole", "Berth", "Shiploader"] as const;
export const keys = {
  id: "ID (optional)",
  address: "Address (geocoded to provide Latitude/Longitude if they are not supplied)",
  latitude: "Latitude (filled in via address if not supplied)",
  longitude: "Longitude (filled in via address if not supplied)",
  archetype: "Archetype (optional)",
  buildYear: "Build Year (optional)",
  floorHeight: "Floor height above ground (metres, optional)"
} as const;
const rawSchemaBase = z.object({
  [keys.id]: z.union([z.string(), z.number()]).nullish().optional(),
  [keys.archetype]: z.enum(archetypes, {
    errorMap: enumErrorMap
  }).nullish().optional(),
  [keys.buildYear]: z.number({
    errorMap: () => ({
      message: `Build Year must be between ${constraints.buildYear.min} and ${constraints.buildYear.max} (inclusive).`
    })
  }).gte(constraints.buildYear.min).lte(constraints.buildYear.max).nullish().optional(),
  [keys.floorHeight]: z.number({
    errorMap: () => ({
      message: `Height Above Ground must be between ${constraints.floorHeight.min} and ${constraints.floorHeight.max} (inclusive).`
    })
  }).gte(constraints.floorHeight.min).lte(constraints.floorHeight.max).nullish().optional()
});
const rawSchemaLocationWithAddress = z.object({
  [keys.address]: z.string().min(constraints.addressLength.min).max(constraints.addressLength.max)
});
const rawSchemaLocationWithCoordinates = z.object({
  [keys.latitude]: latSchema,
  [keys.longitude]: lngSchema
});
const rawSchemaLocation = z.union([rawSchemaLocationWithAddress, rawSchemaLocationWithCoordinates], {
  errorMap: () => ({
    message: "A location must be specified either by providing an Address, or with coordinates by providing both Latitude and Longitude."
  })
});
export const portfolioExportRawSchema = rawSchemaBase.and(rawSchemaLocation);
export type PortfolioExportRawSchema = z.infer<typeof portfolioExportRawSchema>;
const portfolioExportBatchEndpointFailure = z.object({
  type: z.literal("failure"),
  errors: z.array(z.string())
});
const portfolioExportBatchEndpointSuccess = z.object({
  type: z.literal("success")
});
const portfolioExportBatchEndpointResponse = z.discriminatedUnion("type", [portfolioExportBatchEndpointFailure, portfolioExportBatchEndpointSuccess]);
export type PortfolioExportBatchEndpointResponse = z.infer<typeof portfolioExportBatchEndpointResponse>;