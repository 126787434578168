import { Col, Row } from "antd";
import { FC, ReactNode } from "react";
import { JobSchema } from "../types/JobSchema";
import { JobTableBody } from "./JobTableBody";
import { JobTableHeader } from "./JobTableHeader";
export interface JobTableProps<T extends JobSchema> {
  jobSchemas: Required<T>[];
  onRunStart: (schema: T) => void;
  setJobSchemas: (schemas: Required<T>[]) => void;
  children?: ReactNode;
  headerComponent?: FC;
}
export const JobTable = <T extends JobSchema,>(props: JobTableProps<T>) => {
  const Header = props.headerComponent || JobTableHeader;
  return <Row className="text-left mb-8 w-full" data-sentry-element="Row" data-sentry-component="JobTable" data-sentry-source-file="JobTable.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="JobTable.tsx">
        <Header onRunStart={props.onRunStart} data-sentry-element="Header" data-sentry-source-file="JobTable.tsx" />
      </Col>
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="JobTable.tsx">
        {props.children || <JobTableBody jobSchemas={props.jobSchemas} renderers={{
        actions: record => <>Implement actions: {record.name}</>,
        jobInformation: record => <>Implement information: {record.name}</>,
        status: record => <>Implement status: {record.name}</>
      }} />}
      </Col>
    </Row>;
};