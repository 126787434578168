import { Loading } from "@/components/overlays/Loading";
import { Alert, Col, List, Modal, ModalProps, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import type { Changelog, Release } from "@climaterisk/keep-a-changelog/esm/mod";
import { useTranslation } from "react-i18next";
const {
  Title,
  Text
} = Typography;
export const ChangelogModal = (props: ModalProps) => {
  const [changelog, setChangelog] = useState<Changelog | undefined>(undefined);
  const [status, setStatus] = useState<"loading" | "failure" | "success">("loading");
  const {
    t
  } = useTranslation("features");
  useEffect(() => {
    fetch("/_changelog.json").then(data => {
      data.json().then(data => {
        setStatus("success");
        setChangelog(data);
      }).catch(() => setStatus("failure"));
    }).catch(() => setStatus("failure"));
  }, []);
  const render = () => {
    switch (status) {
      case "failure":
        return <Alert type="error" message="Failed to load changelog. Please refresh the page." />;
      case "loading":
        return <div style={{
          position: "relative",
          width: "100%",
          height: "8rem"
        }}>
            <Loading />
          </div>;
      case "success":
        return <List<Release> className="prose" dataSource={changelog!.releases} renderItem={(release, index) => {
          const isFirstRelease = index === changelog!.releases.length - 1;
          const version = release.version?.toString() || "Unknown Version";
          return <List.Item>
                  <Row>
                    <Col span={24}>
                      <Title level={4} style={{
                  marginTop: ".5rem"
                }}>
                        {version}
                      </Title>
                    </Col>
                    <Col span={24}>
                      <ReleaseDetails isFirstRelease={isFirstRelease} release={release as unknown as ChangelogRelease} />
                    </Col>
                  </Row>
                </List.Item>;
        }} />;
    }
  };
  return <Modal footer={null} title={t("changeLog.title")} {...props} data-sentry-element="Modal" data-sentry-component="ChangelogModal" data-sentry-source-file="index.tsx">
      {render()}
    </Modal>;
};
const detailKeys = ["added", "changed", "deprecated", "fixed", "removed", "security"] as const;
type ChangelogRelease = Record<(typeof detailKeys)[number], {
  title: string;
}[]>;
interface ReleaseDetailsProps {
  isFirstRelease?: boolean;
  release: ChangelogRelease;
}
const ReleaseDetails = ({
  isFirstRelease,
  release
}: ReleaseDetailsProps) => {
  const {
    t
  } = useTranslation("features", {
    keyPrefix: "changeLog.categories"
  });
  if (isFirstRelease) {
    return <Text>Initial release.</Text>;
  }
  const items = [];
  for (const key of detailKeys) {
    const data = release[key];
    if (data.length) {
      items.push(<Col span={24}>
          <Title level={5} style={{
          marginTop: "0.5rem"
        }}>
            {t(key)}
          </Title>
          <ul>
            {data.map(({
            title
          }, index) => <li key={index}>{title}</li>)}
          </ul>
        </Col>);
    }
  }
  return items.length ? <Row>{items}</Row> : <Text italic>No data available for this version.</Text>;
};