import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useSession } from "@supabase/auth-helpers-react";
import { Button, Form, Input, Skeleton } from "antd";
import { useState } from "react";
import styles from "./UserPasswordInput.module.scss";
const UserKeyPair = ({
  prompt = "Enter your password to decrypt your portfolio"
}: {
  prompt?: string;
}) => {
  const session = useSession();
  const [, {
    getFromDb
  }] = useKeyPair();
  const [error, setError] = useState("");
  const handlePasswordSubmit = async ({
    password
  }: {
    password: string;
  }) => {
    const {
      error
    } = await getFromDb(password);
    error && setError(error);
  };
  return <>
      <div className={styles.container}>
        <div className={styles.skeletonWrapper}>
          {Array.from({
          length: 4
        }).map((_, idx) => <Skeleton active key={idx} />)}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            <LockOutlined data-sentry-element="LockOutlined" data-sentry-source-file="UserPasswordInput.tsx" />
          </div>
          <div style={{
          lineHeight: 1.5
        }}>{prompt}</div>
          <br />
          <Form layout="vertical" requiredMark={false} onFinish={handlePasswordSubmit} fields={[{
          name: ["email"],
          value: session?.user.email
        }]} onBlur={() => setError("")} data-sentry-element="Form" data-sentry-source-file="UserPasswordInput.tsx">
            <Form.Item name="email" data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx">
              <Input name="email" prefix={<UserOutlined />} disabled={true} data-sentry-element="Input" data-sentry-source-file="UserPasswordInput.tsx" />
            </Form.Item>
            <Form.Item name="password" rules={[{
            required: true,
            message: "Password is required"
          }]} data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx">
              <Input prefix={<LockOutlined />} placeholder="Password" type="password" data-sentry-element="Input" data-sentry-source-file="UserPasswordInput.tsx" />
            </Form.Item>
            <Form.Item data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx">
              <Button type="primary" block htmlType="submit" loading={false} data-sentry-element="Button" data-sentry-source-file="UserPasswordInput.tsx">
                Okay
              </Button>
            </Form.Item>
            {error && <div>Failed to decrypt - your password might be incorrect. Please try again.</div>}
          </Form>
        </div>
      </div>
    </>;
};
export default UserKeyPair;