import { Alert, Button, Modal, Slider } from "antd";
import styles from "./PortfolioExportMaxSize.module.scss";
import { useEffect, useState } from "react";
import { useSetMaxPortfolioSizeMutation, useShowMaxPortfolioModalQuery } from "@/store/services/supabase";
import { PortfolioExportSizeType, portfolioExportSize } from "@/tools/aggregate/portfolio-export/constants";
import { getConfig } from "@/features/branding";
export const PortfolioExportMaxSize = () => {
  const [currPortfolioSize, setPortfolioSize] = useState<PortfolioExportSizeType>(20000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data
  } = useShowMaxPortfolioModalQuery();
  const [setMaxPortfolioSize, {
    error,
    isLoading
  }] = useSetMaxPortfolioSizeMutation();
  const formatter = (value: number | undefined) => {
    const size = portfolioExportSize[value as PortfolioExportSizeType];
    return value !== 120000 ? "Up to " + size : size;
  };
  useEffect(() => {
    data && setIsModalOpen(data);
  }, [data]);
  const handleSubmit = async () => {
    const portfolio = await setMaxPortfolioSize({
      size: currPortfolioSize
    });
    if (!("error" in portfolio)) {
      setIsModalOpen(false);
    }
  };

  // Just in case we run into leaks of this modal.
  const locationText = process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma") ? "in Hong Kong " : "";
  return <>
      <Modal open={isModalOpen} title="Your Portfolio Size" closable={false} footer={<Button type="primary" htmlType="submit" onClick={handleSubmit} loading={isLoading}>
            OK
          </Button>} data-sentry-element="Modal" data-sentry-source-file="PortfolioExportMaxSize.tsx">
        <div className={styles.titleContainer}>
          <div className={styles.blurb}>
            Please use the slide bar below to indicate the annual average total number of properties under finance{" "}
            {locationText}for which your bank acts as the financier. Your declaration will be used to calculate the Fair
            Use Limit of your bank as set out in the{" "}
            <a href={getConfig().featureSet.docs.legal.termsOfService} target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            .
          </div>
          <div className={styles.blurb}>
            The five buckets are:
            <ol>
              <li>Up to 1,000</li>
              <li>1,000 to 20,000</li>
              <li>20,000 to 55,000</li>
              <li>55,000 to 100,000</li>
              <li>{">"} 100,000</li>
            </ol>
          </div>
          <div className={styles.blurb}>
            If your bank falls into buckets 1 to 4, please select the upper limit of the bucket (e.g. please select
            20,000 if your bank falls into bucket 2). If your bank falls into bucket 5, please select 100,000+.
          </div>
        </div>
        <div className={styles.sliderContainer}>
          <Slider marks={portfolioExportSize} min={1000} max={120000} step={null} tooltip={{
          formatter
        }} included={true} value={currPortfolioSize} onChange={val => setPortfolioSize(val as PortfolioExportSizeType)} className={styles.slider} data-sentry-element="Slider" data-sentry-source-file="PortfolioExportMaxSize.tsx" />
        </div>
        <div className={styles.blurb}>
          {"(Your bank's Fair Use Limit will be automatically calculated pro-rata if you register to the platform during the year. Nonetheless, we will grant the full year allocation to the banks who registered in 2024 during the beta version. The additional quota will be adjusted manually and will take up to 5 days to take into effect.)"}
        </div>
        {error && <Alert type="error" message={JSON.stringify(error)} />}
      </Modal>
    </>;
};