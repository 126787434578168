import supabase, { SupabaseDatabase } from "@/features/supabase";
import { ToolsPermissions } from "@/store/services/supabase/types";
import { getSupabaseError, getSupabaseFunctionError } from "@/store/services/supabase/utils";
import { arrayBufferToString } from "@/utils/crypto";
import { FetchBaseQueryError, createApi, fakeBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  AuthMFAGetAuthenticatorAssuranceLevelResponse,
  AuthOtpResponse,
  AuthResponse,
  EmailOtpType,
} from "@supabase/supabase-js";

type UserTable = SupabaseDatabase["public"]["Tables"]["users"]["Row"];
type User = SupabaseDatabase["public"]["Views"]["v_users"]["Row"];
export type Org = SupabaseDatabase["public"]["Tables"]["orgs"]["Row"];
type UserGroup = SupabaseDatabase["public"]["Tables"]["users_groups"]["Row"];
type Group = SupabaseDatabase["public"]["Tables"]["groups"]["Row"];
type Portfolio = SupabaseDatabase["public"]["Tables"]["portfolios"]["Row"];
type PortfolioView = SupabaseDatabase["public"]["Views"]["v_portfolios"]["Row"];
type PortfolioAsset = SupabaseDatabase["public"]["Tables"]["portfolio_assets"]["Row"];
type ToolQuota = SupabaseDatabase["public"]["Tables"]["tool_quotas"]["Row"];

export type GetGroup = Omit<Group, "permissions"> & {
  total_members: number;
  tools_enabled: string[];
  permissions: ToolsPermissions;
};

export const supabaseApi = createApi({
  reducerPath: "users",
  baseQuery: fakeBaseQuery<FetchBaseQueryError>(),
  tagTypes: ["User", "Domain", "Group", "Slug", "Org", "Portfolio", "PortfolioAssets"],
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      providesTags: [{ type: "User", id: "LIST" }],
      queryFn: async () => {
        const { data, error } = await supabase.from("v_users").select("*").order("created_at", { ascending: false });

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    createUser: builder.mutation<
      { url: string; otp: string; expiresAt: string },
      { email?: string; username?: string; groupId: string }
    >({
      invalidatesTags: [{ type: "User", id: "LIST" }],
      queryFn: async ({ email, username, groupId }) => {
        const { data, error } = await supabase.functions.invoke("generate-link", {
          body: JSON.stringify({ email, username, groupId, type: "invite" }),
        });

        return data ? { data } : { error: await getSupabaseFunctionError(error) };
      },
    }),

    inviteUserByEmail: builder.mutation<{ message: string }, { email: string; groupId: string }>({
      invalidatesTags: [{ type: "User", id: "LIST" }],
      queryFn: async ({ email, groupId }) => {
        const { data, error } = await supabase.functions.invoke("invite-user-by-email", {
          body: JSON.stringify({ email, groupId }),
        });
        return data ? { data } : { error: await getSupabaseFunctionError(error) };
      },
    }),

    getOtp: builder.mutation<
      AuthOtpResponse["data"],
      { email: string; url?: string; shouldCreateUser?: boolean; captchaToken?: string }
    >({
      invalidatesTags: [{ type: "User", id: "LIST" }],
      queryFn: async ({ email, url, shouldCreateUser = false, captchaToken }) => {
        const { data, error } = await supabase.auth.signInWithOtp({
          email: email,
          options: {
            shouldCreateUser,
            emailRedirectTo: url,
            captchaToken,
          },
        });
        if (error) {
          return { error: getSupabaseError(error || { message: "Error while generating OTP for a user" }) };
        }
        return { data };
      },
    }),

    verifyOtp: builder.mutation<AuthResponse["data"], { email: string; token: string; otpType?: EmailOtpType }>({
      queryFn: async ({ email, token, otpType }) => {
        const { data, error } = await supabase.auth.verifyOtp({
          token,
          email,
          type: otpType || "email",
        });

        if (error) {
          return { error: getSupabaseError(error || { message: "Error while verifying OTP" }) };
        }
        return { data };
      },
    }),

    recoverUser: builder.mutation<{ url: string; otp: string; expiresAt: string }, { email: string }>({
      invalidatesTags: [{ type: "User", id: "LIST" }],
      queryFn: async ({ email }) => {
        const { data, error } = await supabase.functions.invoke("generate-link", {
          body: JSON.stringify({ email, type: "recovery" }),
        });

        return data ? { data } : { error: await getSupabaseFunctionError(error) };
      },
    }),

    deleteUser: builder.mutation<string, { id: string }>({
      invalidatesTags: [{ type: "User", id: "LIST" }],
      queryFn: async ({ id }) => {
        const { data, error } = await supabase.functions.invoke("delete-user", {
          body: JSON.stringify({ id }),
        });

        if (error) {
          const message = "Error while deleting user";
          return { error: getSupabaseError(error || { message }) };
        }

        return { data };
      },
    }),

    isOwner: builder.query<boolean, void>({
      queryFn: async () => {
        const { data, error } = await supabase.rpc("is_owner");

        return data
          ? { data }
          : {
              error: getSupabaseError(error || { message: "Error checking Owner privilege" }),
            };
      },
    }),

    updateUserGroup: builder.mutation<
      UserGroup[],
      {
        userId: string;
        oldGroupId: string;
        newGroupId: string;
      }
    >({
      invalidatesTags: [{ type: "User", id: "LIST" }, "Group"],
      queryFn: async ({ userId, oldGroupId, newGroupId }) => {
        const { data, error } = await supabase
          .from("users_groups")
          .update({ group_id: newGroupId })
          .eq("user_id", userId)
          .eq("group_id", oldGroupId)
          .select();

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    addKeyPair: builder.mutation<
      {
        keyPair: {
          publicKey: string;
          privateKey: string;
        };
        salt: string;
      },
      {
        publicKey: ArrayBuffer;
        privateKey: ArrayBuffer;
        salt: Uint8Array;
      }
    >({
      queryFn: async ({ publicKey, privateKey, salt }) => {
        const session = await supabase.auth.getSession();
        if (!session.data.session || session.error) {
          return {
            error: getSupabaseError(session.error || { message: "Session not found" }),
          };
        }
        const user = session.data.session.user;

        // const { data, error } = await supabase
        //   .from("users")
        //   .select("privateKey:private_key, publicKey:public_key, salt")
        //   .filter("id", "eq", user.id)
        //   .single();

        // if (error) return { error: getSupabaseError(error) };

        // if (data && data.privateKey && data.publicKey && data.salt) {
        //   return {
        //     error: getSupabaseError({
        //       message: "Unable to generate because key pair exists in the database.",
        //     }),
        //   };
        // }

        const payload = {
          private_key: arrayBufferToString(privateKey),
          public_key: arrayBufferToString(publicKey),
          salt: arrayBufferToString(salt),
        };
        const response = await supabase
          .from("users")
          .update(payload)
          .filter("id", "eq", user.id)
          .select("privateKey:private_key, publicKey:public_key, salt")
          .single();

        if (response.error) return { error: getSupabaseError(response.error) };

        if (!response.data.publicKey || !response.data.privateKey || !response.data.salt) {
          return {
            error: getSupabaseError({
              message: "Some other error.",
            }),
          };
        }

        return {
          data: {
            keyPair: {
              publicKey: response.data.publicKey,
              privateKey: response.data.privateKey,
            },
            salt: response.data.salt,
          },
        };
      },
    }),

    getKeyPair: builder.query<
      {
        keyPair: {
          publicKey: string;
          privateKey: string;
        };
        salt: string;
      },
      void
    >({
      queryFn: async () => {
        const session = await supabase.auth.getSession();
        if (!session.data.session || session.error) {
          return {
            error: getSupabaseError(session.error || { message: "Session not found" }),
          };
        }

        const { data, error } = await supabase
          .from("users")
          .select("privateKey:private_key, publicKey:public_key, salt")
          .filter("id", "eq", session.data.session.user.id)
          .single();

        if (data && data.privateKey && data.publicKey && data.salt) {
          return {
            data: {
              keyPair: {
                publicKey: data.publicKey,
                privateKey: data.privateKey,
              },
              salt: data.salt,
            },
          };
        }

        return {
          error: getSupabaseError(
            error || {
              message: "KeyPair not found. Please generate it first.",
            }
          ),
        };
      },
    }),

    getDomains: builder.query<Org["domains"], void>({
      providesTags: ["Domain"],
      queryFn: async () => {
        const { data, error } = await supabase.from("orgs").select();

        return data ? { data: data![0].domains } : { error: getSupabaseError(error) };
      },
    }),

    getSlug: builder.query<Org["slug"], void>({
      providesTags: ["Slug"],
      queryFn: async () => {
        const { data, error } = await supabase.from("orgs").select("slug").single();

        if (data?.slug) return { data: `@${data.slug}` };

        return {
          error: getSupabaseError(error || { message: "Org Slug not set" }),
        };
      },
    }),

    getGroup: builder.query<GetGroup, { id: string }>({
      providesTags: ["Group"],
      queryFn: async ({ id }) => {
        const { data, error } = await supabase
          .from("groups")
          .select("*, users_groups(count)")
          .order("name", { ascending: true })
          .filter("id", "eq", id)
          .single();

        if (data) {
          const users_groups = data.users_groups as unknown as Array<{ count: number }>;
          const permissions = (data.permissions ? data.permissions : {}) as ToolsPermissions;

          const tools_enabled = Object.keys(permissions).flatMap((toolGroupId) => {
            const toolGroup = permissions[toolGroupId as keyof ToolsPermissions];

            if (!toolGroup) return [];

            return Object.keys(toolGroup).flatMap((toolId) => {
              const tool = toolGroup[toolId as keyof typeof toolGroup];

              if (tool && "enabled" in tool && tool["enabled"]) {
                return `${toolGroupId}.${toolId}`;
              }

              return [];
            });
          });

          return {
            data: {
              ...data,
              permissions,
              total_members: users_groups[0].count,
              tools_enabled,
            },
          };
        }

        return { error: getSupabaseError(error) };
      },
    }),

    getGroups: builder.query<GetGroup[], void>({
      providesTags: ["Group"],
      queryFn: async () => {
        const { data, error } = await supabase
          .from("groups")
          .select("*, users_groups(count)")
          .filter("name", "neq", "New Signups")
          .is("deleted_at", null)
          .order("name", { ascending: true });

        if (data) {
          return {
            data: data.map((d) => {
              const users_groups = d.users_groups as unknown as Array<{ count: number }>;
              const permissions = (d.permissions ? d.permissions : {}) as ToolsPermissions;

              const tools_enabled = Object.keys(permissions).flatMap((toolGroupId) => {
                const toolGroup = permissions[toolGroupId as keyof ToolsPermissions];

                if (!toolGroup) return [];

                return Object.keys(toolGroup).flatMap((toolId) => {
                  const tool = toolGroup[toolId as keyof typeof toolGroup];

                  if (tool && "enabled" in tool && tool["enabled"]) {
                    return `${toolGroupId}.${toolId}`;
                  }

                  return [];
                });
              });
              return {
                ...d,
                permissions,
                total_members: users_groups[0].count,
                tools_enabled,
              };
            }),
          };
        }

        return { error: getSupabaseError(error) };
      },
    }),

    addGroup: builder.mutation<null, Pick<Group, "name" | "description">>({
      invalidatesTags: ["Group"],
      queryFn: async ({ name, description }) => {
        const { data: org_id, error: rpcErr } = await supabase.rpc("get_org_id");

        const { data, error: dbErr } = await supabase.from("groups").insert({ name, description, org_id });

        if (rpcErr || dbErr) {
          const customErr = rpcErr || dbErr || { message: "Error adding group" };
          return { error: getSupabaseError(customErr) };
        }
        return { data };
      },
    }),

    updateGroup: builder.mutation<null, Group>({
      invalidatesTags: ["Group"],
      queryFn: async ({ id, name, description }) => {
        const { data, error } = await supabase.from("groups").update({ name, description }).eq("id", id);

        if (error) {
          const message = "Unknown error updating Group";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    updateGroupPermissions: builder.mutation<null, Pick<Group, "id" | "permissions">>({
      invalidatesTags: ["Group"],
      queryFn: async ({ id, permissions }) => {
        const { data, error } = await supabase.from("groups").update({ permissions }).eq("id", id);

        if (error) {
          const message = "Unknown error updating Group";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    deleteGroup: builder.mutation<null, Pick<Group, "id">>({
      invalidatesTags: ["Group"],
      queryFn: async ({ id }) => {
        const { data, error } = await supabase
          .from("groups")
          .update({ deleted_at: new Date().toISOString() })
          .eq("id", id);

        if (error) {
          const message = "Unknown error deleting Group";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    getOrg: builder.query<Org, void>({
      providesTags: ["Org"],
      queryFn: async () => {
        const { data, error } = await supabase.from("orgs").select().single();

        if (error) {
          const message = "Unknown error fetching org";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    updateOrg: builder.mutation<null, Pick<Org, "name">>({
      invalidatesTags: ["Org"],
      queryFn: async ({ name }) => {
        const { data: org_id } = await supabase.rpc("get_org_id");
        if (!org_id) {
          return { error: getSupabaseError({ message: "Error retrieving current Org" }) };
        }

        const { data, error } = await supabase.from("orgs").update({ name }).eq("id", org_id);

        if (error) {
          const message = "Unknown error updating Org";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    getAssuranceLevel: builder.query<
      AuthMFAGetAuthenticatorAssuranceLevelResponse["data"] & {
        usingOTP: boolean;
        shouldEnrol: boolean;
        hasVerifiedMFA: boolean;
      },
      void
    >({
      queryFn: async () => {
        const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

        if (data) {
          const { currentLevel: curr, nextLevel: next, currentAuthenticationMethods: authMethods } = data;

          const hasVerifiedMFA = curr === "aal2";
          const shouldEnrol = !(next === "aal2" && next !== curr);
          const usingOTP = authMethods.some((a) => a.method === "otp");

          return {
            data: {
              ...data,
              shouldEnrol,
              hasVerifiedMFA,
              usingOTP,
            },
          };
        }
        return { error: getSupabaseError(error) };
      },
    }),

    isUsingOTP: builder.query<{ otp: boolean }, void>({
      queryFn: async () => {
        const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

        console.log(data);

        if (data) {
          const isUsingOTP = data?.currentAuthenticationMethods.some((cur) => cur.method === "otp");

          return {
            data: {
              otp: isUsingOTP,
            },
          };
        }
        return { error: getSupabaseError(error) };
      },
    }),

    addPortfolio: builder.mutation<Portfolio, { name: ArrayBuffer }>({
      invalidatesTags: [],
      queryFn: async ({ name }) => {
        const session = await supabase.auth.getSession();
        if (!session.data.session || session.error) {
          return {
            error: getSupabaseError(session.error || { message: "Session not found" }),
          };
        }

        const { data, error } = await supabase
          .from("portfolios")
          .insert({
            name: arrayBufferToString(name),
            user_id: session.data.session.user.id,
          })
          .select("*")
          .single();

        if (error) {
          const message = "Unknown error adding portfolio";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    getPortfolios: builder.query<PortfolioView[], void>({
      providesTags: [
        { type: "Portfolio", id: "List" },
        { type: "Portfolio", id: "PortfolioExportUsage" },
      ],
      queryFn: async () => {
        const { data, error } = await supabase.from("v_portfolios").select().order("created_at", { ascending: false });

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    updatePortfolioReport: builder.mutation<
      Portfolio,
      {
        id: string;
        summaryId: string | null;
      }
    >({
      invalidatesTags: [{ type: "Portfolio", id: "List" }],
      queryFn: async ({ id, summaryId }) => {
        const { data, error } = await supabase
          .from("portfolios")
          .update({
            summary_id: summaryId,
          })
          .eq("id", id)
          .select()
          .single();

        if (error) {
          const message = "Unknown error updating Portfolio";
          return { error: getSupabaseError(error || { message }) };
        }

        return { data };
      },
    }),

    updatePortfolioProgressCounts: builder.mutation<
      Portfolio,
      {
        total: number;
        success: number;
        error: number;
        pending: number;
        id: string;
      }
    >({
      invalidatesTags: [{ type: "Portfolio", id: "List" }],
      queryFn: async (portfolio) => {
        const { data, error } = await supabase
          .from("portfolios")
          .update({
            total: portfolio.total,
            pending: portfolio.pending,
            error: portfolio.error,
            success: portfolio.success,
          })
          .eq("id", portfolio.id)
          .single();
        if (error) {
          const message = "Unknown error updating Portfolio";
          return { error: getSupabaseError(error || { message }) };
        }

        return { data };
      },
    }),

    getAllPortfolios: builder.query<(Portfolio & { users: Pick<UserTable, "id" | "email"> | null })[], void>({
      providesTags: [{ type: "Portfolio", id: "ListIncludingDeleted" }],
      queryFn: async () => {
        const { data, error } = await supabase
          .from("portfolios")
          .select("*, users(id, email)")
          .order("created_at", { ascending: false });

        if (error) {
          const message = "Unknown error retrieving Portfolio list";
          return { error: getSupabaseError(error || { message }) };
        }

        return { data };
      },
    }),

    addPortfolioAssets: builder.mutation<
      { id: string }[],
      { encrypted_id: ArrayBuffer; portfolio_id: string; data: ArrayBuffer }[]
    >({
      invalidatesTags: [],
      queryFn: async (assets) => {
        const payload = assets.map((asset) => ({
          ...asset,
          encrypted_id: arrayBufferToString(asset.encrypted_id),
          data: arrayBufferToString(asset.data),
        }));

        const { data, error } = await supabase.from("portfolio_assets").insert(payload).select("id");

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Retrieves assets with errors for a Portfolio Export portfolio within a given range.
     */
    getErroneousPortfolioAssets: builder.query<
      PortfolioAsset[],
      {
        portfolioId: string;
        start: number;
        length: number;
      }
    >({
      providesTags: [{ type: "PortfolioAssets", id: "List" }],
      queryFn: async ({ portfolioId, start, length }) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .select()
          .filter("portfolio_id", "eq", portfolioId)
          .filter("errors", "neq", null)
          .range(start, start + length - 1)
          .order("created_at");

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    updatePortfolioAssetsWithErrors: builder.mutation<{ id: string }[], { errors: ArrayBuffer; id: string }>({
      invalidatesTags: [],
      queryFn: async (asset) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .update({ errors: arrayBufferToString(asset.errors) })
          .eq("id", asset.id)
          .select("id");

        return data
          ? { data }
          : { error: getSupabaseError(error || { message: "Something went wrong while updating portfolio assets" }) };
      },
    }),

    setPortfolioAssetIsProcessedToTrue: builder.mutation<string, { id: string }[]>({
      invalidatesTags: [],
      queryFn: async (assets) => {
        const { data, error } = await supabase.rpc("set_portfolio_assets_as_processed", {
          payload: assets,
        });

        return data ? { data } : { error: getSupabaseError(error || { message: "asdf" }) };
      },
    }),

    /**
     * Retrieves successful assets for a Portfolio Export portfolio within a given range.
     */
    getSuccessfulPortfolioAssets: builder.query<
      PortfolioAsset[],
      {
        portfolioId: string;
        start: number;
        length: number;
      }
    >({
      providesTags: [{ type: "PortfolioAssets", id: "List" }],
      queryFn: async ({ portfolioId, start, length }) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .select()
          .filter("portfolio_id", "eq", portfolioId)
          .is("errors", null)
          .range(start, start + length - 1)
          .order("created_at");

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Retrieves a given number of unprocessed portfolio assets (default: 100).
     */
    getUnprocessedPortfolioAssetIds: builder.query<
      {
        supabaseId: string;
        id: string;
        data: string | null;
      }[],
      {
        portfolioId: string;
        length?: number;
      }
    >({
      queryFn: async ({ portfolioId, length = 100 }) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .select("supabaseId:id,id:encrypted_id,data")
          .filter("portfolio_id", "eq", portfolioId)
          .or("is_processed.is.false,is_processed.is.null")
          .range(0, length - 1)
          .order("created_at");

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    getUnprocessedPortfolioAssetCount: builder.query<number | null, { portfolioId: string }>({
      queryFn: async ({ portfolioId }) => {
        const { count, error } = await supabase
          .from("portfolio_assets")
          .select("*", { count: "exact", head: true })
          .filter("portfolio_id", "eq", portfolioId)
          .or("is_processed.is.false,is_processed.is.null");

        if (error) {
          return {
            error: getSupabaseError(error || { message: "Error while getting unprocessed portfolio asset count." }),
          };
        }

        return {
          data: count,
        };
      },
    }),

    /**
     * Returns number of assets with errors within a given portfolio.
     */
    getPortfolioAssetErrorsCount: builder.query<number, string>({
      queryFn: async (portfolioId: string) => {
        const { count, error } = await supabase
          .from("portfolio_assets")
          .select("*", { count: "exact", head: true })
          .filter("portfolio_id", "eq", portfolioId)
          .neq("errors", null);

        return !error ? { data: count || 0 } : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Returns processed number of assets within a given portfolio.
     */
    getPortfolioAssetProcessedCount: builder.query<number, string>({
      queryFn: async (portfolioId: string) => {
        const { count, error } = await supabase
          .from("portfolio_assets")
          .select("*", { count: "exact", head: true })
          .filter("portfolio_id", "eq", portfolioId)
          .is("is_processed", true);

        return !error ? { data: count || 0 } : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Returns total number of assets within a given portfolio.
     */
    getPortfolioAssetTotalCount: builder.query<number, string>({
      queryFn: async (portfolioId: string) => {
        const { count, error } = await supabase
          .from("portfolio_assets")
          .select("*", { count: "exact", head: true })
          .filter("portfolio_id", "eq", portfolioId);

        return !error ? { data: count || 0 } : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Returns total, processed and error count of assets within a given portfolio.
     */
    getPortfolioAssetCount: builder.query<
      {
        total: number;
        processed: number;
        errors: number;
      },
      string
    >({
      queryFn: async (portfolio_id: string) => {
        const { data, error } = await supabase

          .rpc("get_portfolio_asset_count", {
            _portfolio_id: portfolio_id,
          })
          .single();

        return !error
          ? { data: data as { total: number; processed: number; errors: number } }
          : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Retrieves all asset IDs for a given portfolio.
     */
    getAllPortfolioAssetIds: builder.query<Pick<PortfolioAsset, "id" | "encrypted_id">[], string>({
      queryFn: async (portfolioId: string) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .select()
          .filter("portfolio_id", "eq", portfolioId)
          .select("id,encrypted_id");

        return data ? { data } : { error: getSupabaseError(error) };
      },
    }),

    /**
     * Retrieves all successful assets for a given portfolio.
     */
    getAllPortfolioAssetsEncrypted: builder.query<
      {
        encryptedId: string;
        data: string | null;
      }[],
      string
    >({
      queryFn: async (portfolioId: string) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .select()
          .filter("portfolio_id", "eq", portfolioId)
          .is("errors", null)
          .select("data,encrypted_id");

        return data
          ? { data: data?.map(({ data, encrypted_id: encryptedId }) => ({ data, encryptedId })) || [] }
          : { error: getSupabaseError(error) };
      },
    }),

    setPortfolioAssetsProcessed: builder.mutation<null, string[]>({
      invalidatesTags: ["PortfolioAssets"],
      queryFn: async (ids) => {
        const { data, error } = await supabase
          .from("portfolio_assets")
          .update({
            is_processed: true,
          })
          .in("id", ids);

        if (error) {
          const message = "Unknown error updating Portfolio Assets";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data };
      },
    }),

    /**
     * Sets errors on Portfolio Assets.
     */
    setPortfolioAssetsErrors: builder.mutation<
      string,
      {
        portfolioId: string;
        rows: {
          id: string;
          encryptedId: ArrayBuffer;
          errors: ArrayBuffer;
        }[];
      }
    >({
      invalidatesTags: ["PortfolioAssets"],
      queryFn: async ({ portfolioId, rows }) => {
        const data = rows.map(({ id, encryptedId, errors }) => ({
          id,
          portfolio_id: portfolioId,
          encrypted_id: arrayBufferToString(encryptedId),
          errors: arrayBufferToString(errors),
        }));

        const { error } = await supabase.from("portfolio_assets").upsert(data);

        if (error) {
          const message = "Unknown error updating Portfolio Assets";
          return { error: getSupabaseError(error || { message }) };
        }

        return {
          data: "success",
        };
      },
    }),

    /**
     * Deletes a Portfolio Export portfolio.
     *
     * Note: Portfolio list is manually filtered on delete. Adding invalidateTags for Portfolio will cause flickering.
     */
    deletePortfolio: builder.mutation<null, string>({
      queryFn: async (portfolioId) => {
        // const portfolioAssets =
        await supabase.from("portfolio_assets").delete().eq("portfolio_id", portfolioId);

        const portfolio = await supabase
          .from("portfolios")
          .update({ deleted_at: new Date().toISOString() })
          .eq("id", portfolioId);

        const error = portfolio.error;

        if (error) {
          const message = "Unknown error deleting Portfolio";
          return { error: getSupabaseError(error || { message }) };
        }
        return { data: null };
      },
    }),

    generateUsername: builder.query<string, void>({
      queryFn: async () => {
        const { data, error } = await supabase.functions.invoke("generate-username");

        return data ? { data: data.username } : { error: getSupabaseError(error) };
      },
    }),

    showMaxPortfolioModal: builder.query<boolean, void>({
      providesTags: [{ type: "Org", id: "portfolioSize" }],
      queryFn: async () => {
        const isOwner = await supabase.rpc("is_owner");
        if (isOwner.error) {
          return { error: getSupabaseError(isOwner.error || { message: "Error checking Owner privilege" }) };
        }
        if (!isOwner.data) {
          return { data: false };
        }

        const { data, error } = await supabase.from("orgs").select("portfolio_size").single();

        if (error) {
          return { error: getSupabaseError(error || { message: "Error fetching max portfolio size" }) };
        }

        return { data: data.portfolio_size === null };
      },
    }),

    setMaxPortfolioSize: builder.mutation<null, { size: number }>({
      invalidatesTags: [
        { type: "Org", id: "portfolioSize" },
        { type: "Portfolio", id: "PortfolioExportUsage" },
      ],
      queryFn: async ({ size }) => {
        const { data: org_id, error: rpcErr } = await supabase.rpc("get_org_id");

        const { data, error } = await supabase.from("orgs").update({ portfolio_size: size }).filter("id", "eq", org_id);

        if (rpcErr || error) {
          const customErr = rpcErr || error || { message: "Error setting portfolio size" };
          return { error: getSupabaseError(customErr) };
        }
        return { data };
      },
    }),

    getPortfolioExportQuotas: builder.query<ToolQuota[], void>({
      queryFn: async () => {
        const { data, error } = await supabase.from("tool_quotas").select().filter("tool", "eq", "portfolio-export");

        console.log("data: ", data);

        if (error) {
          return { error: getSupabaseError(error || { message: "Error fetching max portfolio size" }) };
        }

        return { data };
      },
    }),
    getPortfolioExportUsage: builder.query<
      SupabaseDatabase["public"]["Functions"]["get_portfolio_export_usage"]["Returns"],
      void
    >({
      queryFn: async () => {
        const { data, error } = await supabase.rpc("get_portfolio_export_usage");

        console.log("data: ", data);

        if (error) {
          return { error: getSupabaseError(error || { message: "Error fetching max portfolio size" }) };
        }

        return { data };
      },
    }),

    getCurrentPortfolioExportUsage: builder.query<
      SupabaseDatabase["public"]["Functions"]["get_current_portfolio_export_usage"]["Returns"] & {
        newRun: {
          enabled: boolean;
          warning: false | "limit" | "error";
          alwaysEnabled: boolean;
        };
      },
      void
    >({
      providesTags: [{ type: "Portfolio", id: "PortfolioExportUsage" }],
      queryFn: async () => {
        const { data: org_id, error: orgError } = await supabase.rpc("get_org_id");

        if (orgError) {
          return {
            error: getSupabaseError(
              orgError || {
                message: "Error while fetching current portfolio usage. Couldn't identify your organisation.",
              }
            ),
          };
        }

        const { data, error } = await supabase.rpc("get_current_portfolio_export_usage", { _org_id: org_id });

        if (!data || error) {
          return { error: getSupabaseError(error || { message: "Error while fetching current portfolio usage" }) };
        }

        const alwaysEnabled = data.percent_limit === 0;
        const enableNewRun = alwaysEnabled ? true : data.percent_limit > data.percent_used;

        const showWarning = () => {
          switch (true) {
            case !enableNewRun:
              return "error";
            case data.percent_used > 90:
              return "limit";
            default:
              return false;
          }
        };

        return {
          data: {
            ...data,
            newRun: {
              alwaysEnabled,
              enabled: enableNewRun,
              warning: showWarning(),
            },
          },
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetDomainsQuery,
  useGetGroupsQuery,
  useCreateUserMutation,
  useInviteUserByEmailMutation,
  useGetOtpMutation,
  useVerifyOtpMutation,
  useDeleteUserMutation,
  useRecoverUserMutation,
  useGetSlugQuery,
  useUpdateGroupMutation,
  useUpdateGroupPermissionsMutation,
  useAddGroupMutation,
  useGetGroupQuery,
  useDeleteGroupMutation,
  useUpdateUserGroupMutation,
  useGetAssuranceLevelQuery,
  useLazyGetAssuranceLevelQuery,
  useGetOrgQuery,
  useUpdateOrgMutation,
  useIsOwnerQuery,
  useAddKeyPairMutation,
  useGetKeyPairQuery,
  useLazyGetKeyPairQuery,
  useGetPortfoliosQuery,
  useGetAllPortfoliosQuery,
  useGetErroneousPortfolioAssetsQuery,
  useAddPortfolioMutation,
  useGetAllPortfolioAssetIdsQuery,
  useLazyGetAllPortfolioAssetIdsQuery,
  useLazyGetUnprocessedPortfolioAssetCountQuery,
  useUpdatePortfolioAssetsWithErrorsMutation,
  useGetUnprocessedPortfolioAssetIdsQuery,
  useLazyGetUnprocessedPortfolioAssetIdsQuery,
  useGenerateUsernameQuery,
  useLazyGenerateUsernameQuery,
  useUpdatePortfolioProgressCountsMutation,
  useShowMaxPortfolioModalQuery,
  useSetMaxPortfolioSizeMutation,
  useGetPortfolioExportQuotasQuery,
  useGetPortfolioExportUsageQuery,
  useGetCurrentPortfolioExportUsageQuery,
} = supabaseApi;
