import { RiskBandsResponseSingleRcp, RiskBandsResponseYear } from "@/store/services/facades/types";
import { RiskBandProps, RiskBandWithLabel } from "./RiskBand";
import { Col, Row } from "antd";
import styles from "./RiskBandsList.module.scss";
export interface RiskBandsListProps extends Omit<RiskBandProps, "rating"> {
  ratings: RiskBandsResponseSingleRcp;
  labelFontSize?: string;
}
export const RiskBandsList = ({
  ratings,
  ...props
}: RiskBandsListProps) => {
  // If we have the custom ratings array, use it. Otherwise, build it.
  const bands: RiskBandsResponseYear[] = "ratings" in ratings ? ratings.ratings : [ratings.current_year, ratings.end_of_mortgage_term, ratings.end_of_century];

  // For laying out the columns in Ant Design;
  const span = 24 / bands.length;
  return <Row className={styles.container} data-sentry-element="Row" data-sentry-component="RiskBandsList" data-sentry-source-file="RiskBandsList.tsx">
      {bands.map(({
      year,
      rating
    }, index) => <Col key={index} span={span}>
          <RiskBandWithLabel label={String(year)} rating={rating} {...props} />
        </Col>)}
    </Row>;
};